import { lazy } from 'react'
import { Navigate } from 'react-router-dom'
import UnsubscribedEmails from './views/UnsubscribedEmail'
import CreateUnsubscribedEmail from './views/UnsubscribedEmail/Create'
const Login = lazy(() => import('src/views/Login'))
const DashboardLayout = lazy(() => import('./layouts/DashboardLayout'))
const MainLayout = lazy(() => import('./layouts/MainLayout'))
const Admins = lazy(() => import('./views/Admins'))
const CreateAdmin = lazy(() => import('./views/Admins/Create'))
const AdminDetails = lazy(() => import('./views/Admins/Edit'))
const BannedWords = lazy(() => import('./views/BannedWords'))
const CreateBannedWord = lazy(() => import('./views/BannedWords/Create'))
const EditBannedWord = lazy(() => import('./views/BannedWords/Edit'))
const BannedWordsUsage = lazy(() => import('./views/BannedWords/Usage'))
const Contractors = lazy(() => import('./views/Contractors'))
const CreateContractor = lazy(() => import('./views/Contractors/Create'))
const EditContractor = lazy(() => import('./views/Contractors/Edit'))
const Dashboard = lazy(() => import('./views/Dashboard'))
const EmailTemplates = lazy(() => import('./views/EmailTemplates'))
const EditEmailTemplate = lazy(() => import('./views/EmailTemplates/Edit'))
const Employers = lazy(() => import('./views/Employers'))
const CreateEmployer = lazy(() => import('./views/Employers/Create'))
const EditEmployer = lazy(() => import('./views/Employers/Edit'))
const ExternalPosts = lazy(() => import('./views/ExternalPosts'))
const ForgotPassword = lazy(() => import('./views/ForgotPassword'))
const NotFound = lazy(() => import('./views/NotFound'))
const Posts = lazy(() => import('./views/Posts'))
const CreatePost = lazy(() => import('./views/Posts/Create'))
const EditPost = lazy(() => import('./views/Posts/Edit'))
const References = lazy(() => import('./views/References'))
const RefundClaims = lazy(() => import('./views/RefundClaim'))
const EditRefundClaim = lazy(() => import('./views/RefundClaim/Edit'))
const Reports = lazy(() => import('./views/Reports'))
const ResetPassword = lazy(() => import('./views/ResetPassword'))
const Settings = lazy(() => import('./views/Settings'))

const routes = [
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      { path: 'dashboard', element: <Dashboard /> },

      { path: 'admins', element: <Admins /> },
      { path: 'admins/new', element: <CreateAdmin /> },
      { path: 'admins/:id', element: <AdminDetails /> },

      { path: 'contractors', element: <Contractors /> },
      { path: 'contractors/:id', element: <EditContractor /> },
      { path: 'contractors/new', element: <CreateContractor /> },

      { path: 'employers', element: <Employers /> },
      { path: 'employers/:id', element: <EditEmployer /> },
      { path: 'employers/new', element: <CreateEmployer /> },

      { path: 'posts', element: <Posts /> },
      { path: 'posts/new', element: <CreatePost /> },
      { path: 'posts/:id', element: <EditPost /> },

      { path: 'external-posts', element: <ExternalPosts /> },

      { path: 'bannedWords', element: <BannedWords /> },
      { path: 'bannedWords/:id', element: <EditBannedWord /> },
      { path: 'bannedWords/new', element: <CreateBannedWord /> },
      { path: 'bannedWords/usage', element: <BannedWordsUsage /> },

      { path: 'unsubscribed', element: <UnsubscribedEmails /> },
      { path: 'unsubscribed/new', element: <CreateUnsubscribedEmail /> },

      { path: 'refund', element: <RefundClaims /> },
      { path: 'refund/:id', element: <EditRefundClaim /> },

      { path: 'reference', element: <References /> },

      { path: 'settings', element: <Settings /> },

      { path: 'reports', element: <Reports /> },

      { path: 'email-templates', element: <EmailTemplates /> },
      { path: 'email-templates/:id', element: <EditEmailTemplate /> },

      { path: '*', element: <NotFound /> },
    ],
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'login', element: <Login /> },
      { path: 'forgot-password', element: <ForgotPassword /> },
      {
        path: 'reset-password',
        element: <ResetPassword />,
      },
      { path: '404', element: <NotFound /> },
      { path: '/', element: <Navigate to="/app/dashboard" /> },
      { path: '*', element: <Navigate to="/404" /> },
    ],
  },
]

export default routes
