import { TextField } from '@material-ui/core'
import React from 'react'

interface Props {
  className?: string
  name: string
  label?: string
  touched?: any
  errors?: any
  value?: any
  type?: string
  select?: boolean
  shrink?: boolean
  disabled?: boolean
  multiline?: boolean
  rows?: number | string
  size?: 'small' | 'medium' | undefined
  margin?: 'normal' | 'dense' | 'none' | undefined
  InputProps?: any
  onBlur?(e: React.FocusEvent<any>): any
  onFocus?(e: React.FocusEvent<any>): any
  onChange?(e: React.ChangeEvent<any>): any
  getIn?(obj: any, name: string): any
}

const InputText: React.FC<Props> = ({
  className,
  name,
  label = '',
  multiline = false,
  rows = 2,
  shrink,
  touched = {},
  type = 'text',
  errors = {},
  value,
  select = false,
  size = 'medium',
  onFocus,
  onBlur,
  onChange,
  getIn,
  children,
  disabled = false,
  margin = 'normal',
  InputProps,
  ...rest
}) => {
  return (
    <TextField
      className={className}
      error={getIn ? Boolean(getIn(touched, name) && getIn(errors, name)) : Boolean(touched[name] && errors[name])}
      helperText={getIn ? getIn(touched, name) && getIn(errors, name) : touched[name] && errors[name]}
      label={label}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      type={type}
      value={value}
      select={select}
      InputProps={InputProps}
      InputLabelProps={{ shrink: shrink }}
      fullWidth
      multiline={multiline}
      minRows={rows}
      margin={margin}
      variant="outlined"
      disabled={disabled}
      onFocus={onFocus}
      size={size}
      {...rest}
    >
      {select && children}
    </TextField>
  )
}

export default InputText
