import { Box, Button, Container, makeStyles } from '@material-ui/core'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import Page from 'src/components/Page'
import Toolbar from 'src/components/Toolbar'
import ListUnsubscribedEmails from './List/ListUnsubscribedEmails'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}))

const UnsubscribedEmails: React.FC = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const handleAdd = () => navigate('new')

  return (
    <Page className={classes.root} title="Unsubscribed Emails">
      <Container maxWidth={false}>
        <Toolbar
          title="Unsubscribed Emails"
          buttons={
            <Box display="flex" justifyContent="flex-end">
              <Button color="primary" variant="contained" onClick={handleAdd}>
                Add Email
              </Button>
            </Box>
          }
        />
        <Box mt={3}>
          <ListUnsubscribedEmails />
        </Box>
      </Container>
    </Page>
  )
}

export default UnsubscribedEmails
