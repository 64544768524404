import { MUIDataTableColumn } from 'mui-datatables'

const tableColumns: MUIDataTableColumn[] = [
  {
    label: 'Email',
    name: 'email',
  },
  {
    label: "Unsubscribe Date",
    name: "createdAt",
    options: {
      customBodyRender: (value: string) => new Date(value).toLocaleString(),
    }
  }
]

export default tableColumns
