import MUIDataTable, { DisplayData, MUIDataTableColumn, MUIDataTableOptions } from 'mui-datatables'
import React from 'react'
import { rowsDeleted, SelectedRows } from 'src/types/Datatable'
import { PaginationDetails } from 'src/types/Paginate'

interface Props<T = any> {
  title?: string
  selectableRows?: 'none' | 'multiple' | 'single' | undefined
  list: T[]
  onClick?(obj: T): void
  serverSide?: boolean
  download?: boolean
  filter?: boolean
  viewColumns?: boolean
  count?: number
  tableColumns: MUIDataTableColumn[]
  elevation?: number
  customToolbarSelect?(selectedRows: SelectedRows, displayData: DisplayData, setSelectedRows: (rows: number[]) => void): any
  customToolbar?(data: { displayData: DisplayData }): any
  onRowsDelete?(data: rowsDeleted): any
  tableState?: PaginationDetails<T>
  setTableState?: React.Dispatch<React.SetStateAction<PaginationDetails<T>>>
  expandableRows?: boolean
  RenderExpandableRow?: ({ rowMeta, list }: { rowMeta: { dataIndex: number; rowIndex: number }; list: any[] }) => any
  rowsPerPageOptions?: number[]
}

const CustomDatatable: React.FC<Props> = ({
  title = '',
  download = true,
  filter = true,
  viewColumns = true,
  selectableRows = 'none',
  list,
  onClick,
  serverSide = false,
  expandableRows = false,
  tableColumns,
  onRowsDelete,
  count = 0,
  customToolbarSelect,
  elevation = 4,
  customToolbar,
  tableState,
  setTableState,
  RenderExpandableRow,
  rowsPerPageOptions,
}) => {
  const [defaultSelectableRows, setDefaultSelectableRows] = React.useState<'multiple' | 'single' | 'none' | undefined>('none')

  const handleClick = React.useCallback(
    (_rowData, rowMeta) => {
      if (onClick) onClick(list[rowMeta.dataIndex].id)
    },
    [onClick, list]
  )

  React.useEffect(() => {
    setDefaultSelectableRows(selectableRows)
  }, [selectableRows])

  const handleDownload = (buildHead: (columns: any) => string, buildBody: (data: any) => string, columns: any, data: any) => {
    const updatedData = [...data]
    data.forEach((columnDataList: any, i: number) => {
      columnDataList.data.forEach((columnData: any, index: number) => {
        if (typeof columnData !== 'string') {
          updatedData[i].data[index] = columns[index].customBodyRender(columnData)
        }
      })
    })

    return buildHead(columns) + buildBody(updatedData)
  }

  const handleExpandableRow = (_rowData: string[], rowMeta: { dataIndex: number; rowIndex: number }) => {
    return RenderExpandableRow ? <RenderExpandableRow rowMeta={rowMeta} list={list} /> : undefined
  }

  const options: MUIDataTableOptions = {
    filterType: 'textField',
    responsive: 'simple',
    search: false,
    print: false,
    page: tableState ? tableState.page : 0,
    download: download,
    filter: filter,
    viewColumns: viewColumns,
    selectableRows: defaultSelectableRows,
    rowsSelected: [],
    draggableColumns: { enabled: true, transitionTime: 100 },
    onRowClick: handleClick,
    serverSide: serverSide,
    elevation: elevation,
    count: count,
    onRowsDelete,
    onTableChange: (action, tableState) => {
      const actions: string[] = ['changePage', 'sort', 'changeRowsPerPage']
      if (action === 'viewColumnsChange') {
        if (tableState.columns.map((column) => column.display).indexOf('true') === -1) {
          setDefaultSelectableRows('none')
        } else {
          setDefaultSelectableRows(selectableRows)
        }
      }

      if (actions.indexOf(action) > -1 && setTableState) {
        setTableState((prev) => ({
          ...prev,
          page: tableState.page,
          rowsPerPage: tableState.rowsPerPage,
          sort: tableState.sortOrder.name ? tableState.sortOrder.name : prev.sort,
          order: tableState.sortOrder.direction ? tableState.sortOrder.direction : prev.order,
        }))
      }
    },
    customToolbarSelect,
    selectableRowsHeader: false,
    customToolbar,
    onDownload: handleDownload,
    expandableRows,
    renderExpandableRow: handleExpandableRow,
    rowsPerPageOptions: rowsPerPageOptions,
  }

  return <MUIDataTable title={title} data={list || []} columns={tableColumns} options={options} />
}

export default CustomDatatable
