import axios from 'axios'
import { AlertError } from 'src/components/Alert'
import config from 'src/config'

export const api = axios.create({ baseURL: config.apiUrl })

api.interceptors.request.use(
  async (config) => {
    config.headers = {
      ...config.headers,
      Authorization: localStorage.getItem('kToken') as string,
    }
    return config
  },
  (err) => {
    throw err.response
  }
)

api.interceptors.response.use(
  (result) => {
    return result.data
  },
  async (err) => {
    // const originalRequest = err.config

    // if (err.response.status === 401) {
    // if (originalRequest.url !== '/auth/refresh-token') {
    //   const tokens: { token: string; refreshToken: string } = await api.post('/auth/refresh-token', {
    //     refreshToken: localStorage.getItem('refreshToken'),
    //   })
    //   if (tokens && tokens.token && tokens.refreshToken) {
    //     localStorage.setItem('kToken', tokens.token)
    //     localStorage.setItem('refreshToken', tokens.refreshToken)
    //     originalRequest.headers.Authorization = tokens.token
    //     return api(originalRequest)
    //   } else {
    //     resetLocalStorage(err)
    //   }
    // } else {
    // resetLocalStorage(err)
    // }
    // } else
    if (err.response && (err.response.status === 404 || err.response.status === 502)) {
      AlertError('There was a problem connecting to the server. Please try again.')
      throw err
    } else {
      AlertError(err.response.data.error)
      throw err.response.data.error
    }
  }
)

// const resetLocalStorage = (err: any) => {
//   localStorage.removeItem('kToken')
//   localStorage.removeItem('refreshToken')
//   localStorage.removeItem('kUser')
//   window.location.href = '/login'
// }
