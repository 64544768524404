import { generateParamsFromPagination } from 'src/helpers/paginationHelper'
import Paginate, { PaginationData } from 'src/types/Paginate'
import IUnsubscribedEmail from 'src/types/UnsubscribedEmail'
import { api } from '.'

type IUnsubscribedEmailApi = {
  get(params: PaginationData<IUnsubscribedEmail>): Promise<Paginate<IUnsubscribedEmail[]>>
  getById(id: string): Promise<IUnsubscribedEmail>
  create(unsubscribedEmail: IUnsubscribedEmail): Promise<IUnsubscribedEmail>
  destroy(id: string): Promise<number>
}

const UnsubscribedEmailApi = (): IUnsubscribedEmailApi => {
  const bannerWordApi = '/admin/unsubscribed'

  const get = (params: PaginationData<IUnsubscribedEmail>): Promise<Paginate<IUnsubscribedEmail[]>> =>
    api.get(`${bannerWordApi}${generateParamsFromPagination(params)}`)

  const getById = (id: string): Promise<IUnsubscribedEmail> => api.get(`${bannerWordApi}/${id}`)

  const create = (unsubscribedEmail: IUnsubscribedEmail): Promise<IUnsubscribedEmail> => api.post(bannerWordApi, unsubscribedEmail)

  const destroy = (id: string): Promise<number> => api.delete(`${bannerWordApi}/${id}`)

  return { get, getById, create, destroy }
}

export default UnsubscribedEmailApi()
