import { Box, Button, Card, CardContent, Container, Divider, Grid, makeStyles, Typography } from '@material-ui/core'
import { useFormik } from 'formik'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import UnsubscribedEmailApi from 'src/api/unsubscribedEmail'
import AppContext from 'src/AppContext'
import { AlertSuccess } from 'src/components/Alert'
import InputText from 'src/components/InputText'
import Page from 'src/components/Page'
import Toolbar from 'src/components/Toolbar'
import { Types } from 'src/reducers/AppReducer'
import IUnsubscribedEmail, { unsubscribedEmailInitialValue } from 'src/types/UnsubscribedEmail'
import validationSchema from 'src/views/UnsubscribedEmail/validationSchema'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}))

const CreateUnsubscribedEmail: React.FC = () => {
  const pageName: string = 'Create Unsubscribed Email'
  const classes = useStyles()
  const navigate = useNavigate()

  const { dispatch } = React.useContext(AppContext)

  const onSubmit = (values: IUnsubscribedEmail) => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    UnsubscribedEmailApi.create({ email: values.email } as IUnsubscribedEmail)
      .then(() => {
        AlertSuccess('Email saved successfully!')
        navigate('/app/unsubscribed')
      })
      .catch((err: Error) => console.log(err))
      .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
  }

  const { errors, touched, values, handleBlur, handleChange, handleSubmit } = useFormik({
    initialValues: unsubscribedEmailInitialValue,
    onSubmit,
    validationSchema,
  })

  return (
    <Page className={classes.root} title={pageName}>
      <Container maxWidth={false}>
        <form onSubmit={handleSubmit}>
          <Toolbar
            title={pageName}
            showBack={true}
            buttons={
              <Box display="flex" justifyContent="flex-end">
                <Button color="primary" variant="contained" type="submit">
                  Save
                </Button>
              </Box>
            }
          />
          <Box mt={3}>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={12}>
                <Card>
                  <CardContent>
                    <Typography variant="subtitle1">Unsubscribed Email</Typography>
                    <Divider />
                    <Grid container spacing={2}>
                      <Grid item xs={12} lg={6}>
                        <InputText
                          errors={errors}
                          touched={touched}
                          label="Email"
                          name="email"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.email}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Container>
    </Page>
  )
}

export default CreateUnsubscribedEmail
