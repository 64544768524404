import { PaginationData, PaginationDetails } from 'src/types/Paginate'

export const generateParamsFromPagination = <T>(paginationData: PaginationData<T>) => {
  let params: string = `?`

  params = params.concat(`limit=${paginationData.limit ? paginationData.limit : 0}&`)
  params = params.concat(`skip=${paginationData.skip ? paginationData.skip : 0}`)
  if (paginationData.sort) params = params.concat(`&sort=${paginationData.sort}`)
  if (paginationData.order) params = params.concat(`&order=${paginationData.order}`)

  if (paginationData.filter) {
    const filter = paginationData.filter
    Object.keys(filter).forEach((key: string) => {
      if (filter[key]) params = params.concat(`&${key}=${filter[key]}`)
    })
  }

  return params
}

export const generatePaginationData = <T>(paginationDetails: PaginationDetails<T>) => {
  return {
    skip: paginationDetails.page * paginationDetails.rowsPerPage,
    limit: paginationDetails.rowsPerPage,
    sort: paginationDetails.sort || 'createdAt',
    order: paginationDetails.order || 'desc',
    filter: paginationDetails.filter || {},
  }
}
