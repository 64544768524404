import { Box, Card } from '@material-ui/core'
import clsx from 'clsx'
import React from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { useNavigate } from 'react-router-dom'
import UnsubscribedEmailApi from 'src/api/unsubscribedEmail'
import AppContext from 'src/AppContext'
import { AlertSuccess } from 'src/components/Alert'
import CustomDatatable from 'src/components/CustomDatatable'
import { generatePaginationData } from 'src/helpers/paginationHelper'
import { Types } from 'src/reducers/AppReducer'
import { rowsDeleted } from 'src/types/Datatable'
import Paginate, { PaginationDetails } from 'src/types/Paginate'
import IUnsubscribedEmail from 'src/types/UnsubscribedEmail'
import tableColumns from './tableColumns'

const ListUnsubscribedEmails: React.FC<{ className?: string }> = ({ className, ...rest }) => {
  const [tableState, setTableState] = React.useState<PaginationDetails<IUnsubscribedEmail>>({
    page: 0,
    rowsPerPage: 10,
    sort: undefined,
    order: undefined,
    filter: undefined,
  })
  const [unsubscribedEmailList, setUnsubscribedEmailList] = React.useState<Paginate<IUnsubscribedEmail[]>>({ data: [], count: 0 })
  const { dispatch } = React.useContext(AppContext)
  const navigate = useNavigate()

  const handleClick = (id: string) => navigate(id)

  const getData = React.useCallback(() => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    UnsubscribedEmailApi.get(generatePaginationData(tableState))
      .then((unsubscribedEmails: Paginate<IUnsubscribedEmail[]>) => {
        setUnsubscribedEmailList(unsubscribedEmails)
      })
      .catch(() => { })
      .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
  }, [dispatch, tableState])

  React.useEffect(() => {
    getData()
  }, [getData])

  const onRowDelete = (rows: rowsDeleted) => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    UnsubscribedEmailApi.destroy(unsubscribedEmailList.data[rows.data[0].dataIndex].id)
      .then(() => {
        AlertSuccess('Email deleted successfully!')
        unsubscribedEmailList.data.splice(rows.data[0].dataIndex, 1)
      })
      .catch((err: Error) => console.log(err))
      .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
  }

  return (
    <>
      <Card className={clsx(className)} {...rest}>
        <PerfectScrollbar>
          <Box>
            <CustomDatatable
              list={unsubscribedEmailList.data}
              count={unsubscribedEmailList.count}
              tableColumns={tableColumns}
              onClick={handleClick}
              selectableRows="single"
              tableState={tableState}
              setTableState={setTableState}
              onRowsDelete={onRowDelete}
              serverSide
              filter={false}
              download={false}
            />
          </Box>
        </PerfectScrollbar>
      </Card>
    </>
  )
}

export default ListUnsubscribedEmails
