type IUnsubscribedEmail = {
  id: string
  email: string
}

export const unsubscribedEmailInitialValue: IUnsubscribedEmail = {
  id: '',
  email: '',
}

export const unsubscribedEmailFilterInitialValue: IUnsubscribedEmail = ({
  email: '',
} as unknown) as IUnsubscribedEmail

export default IUnsubscribedEmail
